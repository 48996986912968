.LogoSearch{
    display: flex;
    gap: 0.75rem;
}
.search{
    display: flex;
    background-color: var(--inputColor);
    border-radius: 10px;
    padding: 5px;
}
.search>input{
    background-color: transparent;
    border: none;
    outline: none;
}
.SearchIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(106.23deg, #f99827, #f95f35 100%);
    border-radius: 5px;
    padding: 4px;
    color: white;
}
.SearchIcon:hover{
    cursor: pointer;
}