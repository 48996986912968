.FollowersCard{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.7rem;
    gap: 1rem;
    font-size: 13px;
}
.followers{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.followers>div{
    display: flex;
    gap: 10px;
}
.followerImg{
    width: 3.2rem;
    border-radius: 50%;
    height: 3.2rem ;
}
.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
h3{
    align-self: center;
}
.name>span:nth-of-type(1){
    font-weight: bold;
}
.fc-button{
    height: 2rem;
    padding-left: 20px;
    padding-right: 20px;
}
.unfollowButton{
    color: var(--orange);
    border: 2px solid var(--orange);
    cursor: pointer;
    background: transparent 
}
