.Auth{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    gap: 4rem;
    position: relative;
}
.Auth>div{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Left-side */
.A-left{
    gap: 2rem;
}

.A-left>img{
    height: 4rem;
    width: 4rem;
}

.webName>h1{
    font-size: 3rem;
    background-color: red;
    /* create the gradient */
    background-image: var(--buttonBg);
    /* background size and repeat properties */
    background-size: 100%;
    background-repeat: repeat;
    /* use text as mask for the background */
    /* this will show the gradient as a text color instead of elemetn background */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent
}
.webName>h6{
   font-size: 0.85rem; 
}

.infoForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.infoInput{
    border: none;
    outline: none;
    background-color: var(--inputColor);
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    
}
.wide{
    width: 20rem;
}
.infoForm>div{
    display: flex;
    gap: 1rem;
    height: 2rem;
    width: 90%;
}

.infoButton{
    width: 6rem;
    height: 2rem;
    align-self: flex-end;
}

.authForm{
    background-color: var(--cardColor);
    border-radius: 1rem;
    padding: 1rem;
    
}

.authForm>div{
    width: 100%;
    align-items: center;
    justify-content: center;
    
}
 